var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("staff.title")) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "settings" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.bookings,
                      callback: function ($$v) {
                        _vm.bookings = $$v
                      },
                      expression: "bookings",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("booking.widget_title")))]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.tasks,
                      callback: function ($$v) {
                        _vm.tasks = $$v
                      },
                      expression: "tasks",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("task.widget_title")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-group mb-3" },
              [
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c(
                    "label",
                    {
                      staticClass: "input-group-text",
                      attrs: { for: "staffType" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dashboard.widgets.view_by")) + " "
                      ),
                    ]
                  ),
                ]),
                _c("b-form-select", {
                  attrs: {
                    id: "staffType",
                    "data-vv-as": _vm.$t("dashboard.widgets.view_by"),
                    "data-vv-name": "dashboard.widgets.view_by",
                    "data-vv-delay": "500",
                    options: _vm.optionTypes,
                    "text-field": "label",
                    "value-field": "key",
                  },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                }),
              ],
              1
            ),
            _vm.loaded
              ? _c("StaffList", {
                  attrs: {
                    staffs: _vm.staffs,
                    sortKey: _vm.profile.sortKey,
                    sortOrder: _vm.profile.sortOrder,
                    columns: _vm.profile.columns,
                  },
                  on: {
                    sortChanged: _vm.onSortChanged,
                    columnVisible: _vm.onColumnVisible,
                    openStaff: _vm.onOpenStaff,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }